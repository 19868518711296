import * as React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <div className="footer">
    <footer className="footer-container container">
      <div className="footer__grid">
        <div className="footer__grid--item">
          <h4>Menu</h4>
          <ul>
            <li><Link to="/#about">About</Link></li>
            <li><Link to="/#team">Team</Link></li>
            <li><Link to="/#games">Games</Link></li>
            <li><Link to="/#contact">Contact</Link></li>
            <li><Link to="https://blog.twoj.io/">Blog</Link></li>
          </ul>
        </div>
        <div className="footer__grid--item">
          <h4>Games</h4>
          <ul>
            <li><Link to="/#games">Yolker</Link></li>
            <li><Link to="/#games">Yolker Run 3D</Link></li>
          </ul>
        </div>
        <div className="footer__grid--item">
          <h4>Other</h4>
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/terms">Terms &amp; conditions</Link></li>
          </ul>
        </div>
        <div className="footer__grid--item">
          <img src="../images/twoi-w.svg" alt="twoj.io" className="footer__logo"/>
          <p>© {new Date().getFullYear()} twoj.io. All rights reserved.</p>
        </div>
      </div>
      <div className="footer__by">
        <p>Designed by <a href="https://andrewbro.com/" className="fix">Andrew Bro</a></p>
      </div>
    </footer>
  </div>
);

export default Footer;
